body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.booking_details_sqare {
  width: 1.1rem;
  height: 0.7rem;
  margin-right: 0.35rem;
}

.weekoff_styles {
  background-color: #6d6d6d !important;
  color: white !important;
}

.hold_styles {
  background-color: #ffd400 !important;
  color: black !important;
}

.completed_styles {
  background-color: #95d904 !important;
  color: white !important;
}

.noVehicle_styles {
  background-color: #15cdff !important;
  color: white !important;
}

.incompleted_styles {
  background-color: #e50019 !important;
  color: white !important;
}

